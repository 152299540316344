
import { defineComponent } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './data/useApAuditListData';

import router from '@/router';

export default defineComponent({
  name: 'apAuditList',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();

    const back = () => {
      router.push('/erp/financial/accruedCope');
    };

    return {
      back,

      columnList,
      tableRef,
    };
  },
});
